.d-featured-nused {
  @apply mb-10;
  @apply bg-blue-faint;
  @apply p-6;
  @apply rounded-3xl;

  @apply grid;
  grid-template-areas:
    'header'
    'figure'
    'content';

  @screen md {
    @apply p-8;
    @apply grid-cols-2;
    grid-template-areas:
      'header figure'
      'content figure';
  }

  @screen lg {
    @apply px-[68px];
    @apply py-6;
    @apply gap-x-6;
  }

  & > header {
    grid-area: header;

    @screen lg {
      @apply mt-[34px];
    }

    h2 {
      @apply mb-[27px];
      @apply text-[22px];
      @apply leading-[30px];
      @apply font-medium;
      @apply text-left;

      @screen md {
        @apply my-0;
      }

      img {
        @apply inline;
        @apply w-[81px];
        @apply h-7;
        @apply py-2;
        @apply pl-3;
        @apply pr-2.5;
        @apply rounded-[5.54px];

        @screen md {
          @apply inline;
          @apply w-[90px];
          @apply h-[31px];
          @apply rounded-[6.33px];
        }

        @screen lg {
          @apply inline;
          @apply w-[114px];
          @apply h-10;
          @apply py-2.5;
          @apply pr-4;
          @apply pl-4;
          @apply rounded-[6.94px];
        }
      }
    }
  }

  & > figure {
    grid-area: figure;

    @apply flex;
    @apply flex-col;
    @apply justify-center;

    @screen md {
      @apply flex-row;
      @apply items-start;
    }

    @screen lg {
      @apply justify-center;
      @apply items-center;
    }

    img {
      @apply bg-transparent;
      @screen md {
        @apply w-max;
      }

      @screen lg {
        @apply max-h-full;
      }
    }
  }

  & > section {
    grid-area: content;

    @apply mb-[1px];
    @apply mt-6;

    @screen md {
      @apply md:mr-4;
    }

    @screen lg {
      @apply mb-[34px];
      @apply mt-4;
      @apply mr-0;
      @apply max-w-[493px];
    }

    & > p {
      @apply my-0;
      @apply text-14px;
      @apply leading-6;

      @screen md {
        @apply leading-[21px];
      }

      @screen lg {
        @apply text-base;
        @apply leading-6;
      }
    }
  }

  &__cta-wrapper {
    @apply flex;
    @apply flex-row;
    @apply justify-center;
    @apply mt-8;

    @screen md {
      @apply mt-4;
      @apply justify-start;
    }

    @screen lg {
      @apply mt-6;
    }
  }

  &__cta-button {
    @apply text-blue-dark;
    @apply text-base;
    @apply font-semibold;
    @apply leading-[18px];
    @apply border-blue-dark;
    @apply border-2;
    @apply rounded-lg;
    @apply sm:px-[30px];
    @apply py-4;
    @apply flex;
    @apply flex-row;
    @apply justify-center;
    @apply w-full;

    @screen md {
      @apply w-auto;
    }
  }
}
