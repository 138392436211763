@use '/styles/mixin/clippath.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/truncate.module.scss' as *;
@use '/styles/mixin/scroll.module.scss' as *;

.drive-featured-post-block {
  &__side-image {
    @apply relative;
    @apply flex;
    @apply flex-wrap;
    @screen md {
      @apply overflow-x-hidden;
      flex-direction: row-reverse;
      @apply h-[21rem];
    }
    @screen lg {
      @apply h-[25.5rem];
      &--page-takeover {
        @apply overflow-hidden;
        [class*='featuredSideImage_drive-featured-post-block__side-image__image--container__image'] {
          @apply max-w-[744px]; // exact 60vw of 1240 since this container is 60vw by default
        }
        [class*='featuredSideImage_drive-featured-post-block__side-image__content'] {
          @apply pl-4;
        }
      }
    }

    &__image--container {
      @apply relative;
      @apply z-10;
      @apply w-full;
      @screen md {
        @apply w-[56%];
      }
      &__image {
        @apply w-screen;
        margin-left: calc(-50vw + 50%);
        @screen md {
          @apply w-full;
          @apply m-0;
        }

        img {
          @apply w-full;
          @apply h-full;
          @apply object-cover;
          @apply object-center;
          @screen md {
            @include clipPath(20% 0, 100% 0, 100% 100%, 5% 100%);
            @apply h-[21rem];
          }
          @screen lg {
            @apply h-[25.5rem];
          }
        }
      }
    }

    &__content {
      @apply w-full;
      @apply relative;
      @apply h-[23rem];
      @screen md {
        @apply h-full;
        @apply w-[44%];
      }
      &:before {
        @apply absolute;
        content: '';
        @apply w-full;
        @apply h-full;
        @apply bg-gray-100;
        @apply w-screen;
        @apply z-0;
        left: calc(-50vw + 50%);
        @screen md {
          @apply left-0;
        }
      }
      &--container {
        @apply absolute;
        @apply top-1/2;
        @apply -translate-y-1/2;
        @screen md {
          @apply max-w-[18.75rem];
          @apply ml-10;
        }
      }
    }

    &__title {
      @include font-heading('mobile', 'h2');
      @apply text-black;
      @include truncate(3);
      @apply self-center;
      @screen lg {
        @include font-heading('desktop', 'h2');
      }
    }

    &__text {
      @include font-text('desktop', 'large');
      @apply text-black;
      @apply pt-8;
      @screen md {
        @include font-text('mobile', 'large');
        @apply pt-0;
        @apply max-w-[27rem];
      }
      @screen lg {
        @include font-text('desktop', 'large');
        @apply pt-4;
      }
    }

    &__buttonWrapper {
      @apply pt-0;
    }

    &__button {
      @apply border-black;
      @apply text-black;
      @apply min-w-[12.5rem];
      @apply inline-block;
      @include font-button('desktop');
    }
  }
  &__side-image--splitL {
    @screen md {
      flex-direction: row;
      [class*='drive-featured-post-block__side-image__image--container__image'] {
        @apply max-w-[744px]; // exact 60vw of 1240 since this container is 60vw by default
        img {
          @include clipPath(0 0, 80% 0, 95% 100%, 0% 100%);
        }
        @screen l {
          @apply ml-0;
        }
      }
      [class*='featuredSideImage_drive-featured-post-block__side-image__content'] {
        &:before {
          max-width: 1440px;
          @apply w-[70vw];
          left: calc(-60vw + 100%);
          @screen l {
            width: calc(100% + 10vw);
            left: -10vw;
          }
        }
      }
      [class*='featuredSideImage_drive-featured-post-block__side-image__content--container'] {
        @screen md {
          @apply ml-0;
        }
      }
    }
  }

  &__full-width-layout {
    &[class^='featuredSideImage_drive-featured-post-block__side-image'] {
      @apply overflow-x-visible;
    }
    &[class^='featuredSideImage_drive-featured-post-block__side-image'],
    [class^='featuredSideImage_drive-featured-post-block__side-image__image--container__image']
      img {
      @screen lg {
        @apply h-[28.5rem];
      }
    }
    [class^='featuredSideImage_drive-featured-post-block__side-image__image--container'] {
      @screen md {
        @apply w-[60%];
      }
    }
    [class^='featuredSideImage_drive-featured-post-block__side-image__image--container__image'] {
      @screen md {
        @apply w-[60vw];
      }
      @media (min-width: 1441px) {
        @apply w-full;
      }
    }
    [class^='featuredSideImage_drive-featured-post-block__side-image__content'] {
      @screen md {
        @apply w-[40%];
      }
      &:before {
        @screen md {
          @apply w-[60vw]; // same as the image container for clip
          left: calc(-50vw + 100%);
        }
        @media (min-width: 1441px) {
          width: calc(100% + 10vw);
          @apply left-0;
        }
      }
    }

    &[class*='featuredSideImage_drive-featured-post-block__side-image--splitL'] {
      @screen md {
        [class*='drive-featured-post-block__side-image__image--container__image'] {
          @apply ml-[-100px];
          img {
            @include clipPath(0 0, 100% 0, 85% 100%, 0% 100%);
          }
        }
      }
      @screen l {
        @apply overflow-x-hidden;
      }
      [class^='featuredSideImage_drive-featured-post-block__side-image__content']:before {
        @screen md {
          @apply w-[70vw]; // same as the image container for clip
          left: calc(-70vw + 100%);
        }
        @media (min-width: 1441px) {
          width: calc(100% + 15vw);
          @apply left-[-15vw];
        }
      }
    }
    [class*='featuredSideImage_drive-featured-post-block__side-image__content--container'] {
      @screen md {
        @apply w-full;
        @apply max-w-[27rem];
        @apply ml-0;
      }
      @media (min-width: 1441px) {
        @apply left-8;
      }
    }
    [class*='featuredSideImage_drive-featured-post-block__side-image__title'] {
      @include truncate(2);
    }
    [class*='featuredSideImage_drive-featured-post-block__side-image__buttonWrapper'] {
      @apply pt-4;
    }
  }
}
