$pointPositions: (
  topLeft: 0 0,
  topRight: 0 100%,
  bottomLeft: 100% 0,
  bottomRight: 100% 100%
);

@function getPosition($point) {
  @if map-has-key($pointPositions, $point) == true {
    @return map-get($pointPositions, $point);
  } @else {
    @return $point;
  }
}

/*
*list of x y | pointPosition
*usage example: @include clipPath(15% 0, 'bottomLeft','bottomRight', 2% 100%);
*/
@mixin clipPath($points...) {
  $positions: ();
  @each $point in $points {
    $positions: append($positions, getPosition($point), 'comma');
  }
  clip-path: polygon($positions);
}
