@use '/styles/mixin/fonts.module.scss' as *;
@use './mixin.module.scss' as *;

.drive-cross-page-navigation-footer {
  @apply bg-black;
  @apply w-screen;
  margin-left: calc(-50vw + 50%);
  @apply pb-12;
  @screen xl {
    @apply pb-15;
  }

  &__description {
    @apply flex;
    @apply justify-center;
    @apply text-gray-500;
    @apply pt-12;
    @apply pb-3;
    @include font-subtext('mobile', 'large');
    @screen md {
      @apply justify-center;
      @apply pt-14;
      @include font-subtext('desktop', 'large');
    }
    @screen xl {
      @apply pb-5;
    }
    @screen lg {
      &--page-takeover {
        @apply w-full;
        @apply ml-0;
      }
    }
  }

  &__sub-nav {
    ul[class*='sub-navigation_drive-sub-navigation__container'] > li {
      &:after {
        @apply w-full #{!important};
        @apply left-0 #{!important};
      }
    }

    [class*='sub-navigation_drive-sub-navigation__container'] {
      @apply justify-center;
      a {
        @apply no-underline;
      }
    }
    div[class*='sub-navigation_drive-sub-navigation']:not([class*='sub-navigation_drive-sub-navigation-spacer']) {
      @apply px-7;
    }
    [class*='sub-nav-sticky'] {
      @apply static;
      @apply z-20;
    }
  }

  &__links-container {
    @apply flex;
    @apply flex-col;
    @apply self-center;
    @apply py-3;
    @apply mx-auto;
    @apply text-white;
    @apply relative;
    a {
      @apply flex;
      @apply justify-center;
      @apply items-center;
      @apply mx-4;
      h3 {
        @apply flex;
        @apply justify-center;
        @apply items-center;
        @apply text-white;
        @apply py-[10px];
        @include font-pullquote('mobile');
        @apply text-[19px];
        @screen md {
          @include font-heading('mobile', 'h4');
        }
        @screen xl {
          @include font-heading('desktop', 'h3');
        }
      }
      &:hover h3 {
        @media (hover: hover) {
          @include customUnderline {
            @apply bottom-[9px];
            @apply bg-white;
            @screen md {
              @apply bottom-1;
            }
          }
        }
      }
    }
    @screen md {
      @apply flex-row;
      @apply justify-around;
      @apply items-baseline;
    }
    @screen l {
      @apply max-w-1240;
      @apply mx-auto;
    }
  }
  &__link--highlight > h3 {
    @include customUnderline {
      @apply bottom-[9px];
      @apply bg-white;
      @screen md {
        @apply bottom-1;
      }
    }
  }
}
.drive-cross-page-navigation-header {
  @apply flex;
  @apply justify-center;
  @include font-text('mobile', 'medium');
  @apply bg-white;
  @apply text-black;
  @apply pt-[42px];
  @screen md {
    @apply justify-center;
    @apply pt-[22px];
  }

  // note that underlining is applied to <li> outside more dropdown and to <a>  inside more dropdown.
  &__sub-nav {
    [class*='sub-navigation_drive-sub-navigation__container'] {
      @apply justify-center;
      a {
        @apply no-underline;
      }

      [class*='sub-navigation_drive-sub-navigation__menu-item--custom-highlight'] {
        @include customUnderline {
          @apply hidden;
        }
        a {
          @include customUnderline();
        }
      }
      // list item except more button
      [class*='sub-navigation_drive-sub-navigation__menu-item'] {
        &:not([class*='sub-navigation_drive-sub-navigation__menu-item--dropdown-wrapper']) {
          @apply transition-none;
          @screen md {
            @apply px-0;
          }
          &:hover {
            a {
              @media (hover: hover) {
                @include customUnderline;
              }
            }
          }
        }
      }
      // list item inside more dropdown
      [class*='sub-navigation_drive-sub-navigation__menu-item--dropdown-wrapper'] {
        [class*='more-dropdown_drive-sub-navigation__more-dropdown__item-list'] li {
          @screen md {
            @apply px-3;
          }
        }
      }
    }
    // non-sticky only
    & > [class*='sub-navigation_drive-sub-navigation-spacer'] > :not([class*=' sub-nav-sticky']) {
      @apply bg-white;
      [class*='sub-navigation_drive-sub-navigation'] {
        @apply bg-white;
        a {
          @apply text-black;
        }
        [class*='sub-navigation_drive-sub-navigation__menu-item'] {
          @apply text-black;
          @apply bg-white;
          [class*='more-dropdown_drive-sub-navigation__more-dropdown'] {
            @apply border-black;
            @apply bg-white;
            img {
              @apply bg-black;
              @apply invert;
            }
          }
        }
      }
    }
    & > [class*='sub-navigation_drive-sub-navigation-spacer'] > [class*=' sub-nav-sticky'] {
      @apply px-5;
      [class*='sub-navigation_drive-sub-navigation'] {
        & > [class*='sub-navigation_drive-sub-navigation__menu-item']:hover {
          @include customUnderline {
            @apply bg-white;
          }
          a {
            @include customUnderline {
              @apply hidden;
            }
          }
        }
        & > [class*='sub-navigation_drive-sub-navigation__menu-item--dropdown-wrapper'] {
          @include customUnderline {
            @apply hidden;
          }
        }
        //custom hight light - sticky
        [class*='sub-navigation_drive-sub-navigation__menu-item--custom-highlight'] {
          @include customUnderline {
            @apply bg-white;
            @apply flex;
          }
        }
        //custom hight light - sticky - more dropdown
        & > [class*='sub-navigation_drive-sub-navigation__menu-item--dropdown-wrapper'] {
          [class*='sub-navigation_drive-sub-navigation__menu-item--custom-highlight'] {
            @include customUnderline {
              @apply hidden;
            }
            a {
              @include customUnderline {
                @apply bg-white;
                @apply flex;
              }
            }
          }
        }
      }
    }
  }
}
