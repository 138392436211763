@use '/styles/mixin/fonts.module.scss' as *;

.drive-cta-link {
  @apply flex;
  @apply items-center;
  @apply bg-gray-200;
  @apply justify-between;
  @apply px-4;
  @apply py-4;
  @apply mx-auto;
  @apply min-h-14;
  @screen md {
    @apply w-[680px];
    @apply py-2;
  }
  @screen lg {
    @apply w-[805px];
  }

  &__content,
  &__content * {
    @apply mr-2.5;
    @include font-subtext('mobile', 'medium');
    @screen md {
      @include font-subtext('mobile', 'large');
    }
    @screen lg {
      @include font-subtext('desktop', 'large');
    }
  }
  :global(.inline) {
    display: inline;
    &:not(last-child) {
      @apply pr-1;
    }
    a {
      @apply text-blue-darkest;
      @apply no-underline;
    }
  }

  &__icon {
    @apply h-6;
    @apply w-auto;
    @screen md {
      @apply h-8;
    }
  }
}
