@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/truncate.module.scss' as *;
@use '/styles/mixin/scroll.module.scss' as *;

.drive-featured-post-block__inline {
  @apply relative;
  @apply mx-auto;
  width: 98%;
  @screen sm {
    width: 96%;
    min-width: 680px;
  }
  @screen xl {
    @apply w-full;
    max-width: 1216px;
  }

  &__background-image {
    img {
      @apply w-full;
      @apply object-cover;
      @apply object-right;
      @apply rounded-2xl;
      @apply h-[512px];
      @screen sm {
        @apply object-center;
        @apply h-[21.5rem];
      }
      @screen lg {
        @apply w-[1440px];
      }
    }
  }
  &__contentwrapper-container {
    @apply absolute;
    @apply h-[512px];
    @apply bottom-0;
    @apply left-1/2;
    transform: translateX(-50%);
    @apply justify-between;
    @apply w-full;

    background: linear-gradient(0deg, #000 40%, rgba(0, 0, 0, 0) 75%);
    @apply rounded-2xl;
    @screen sm {
      @apply absolute;
      @apply h-[21.5rem];
      @apply top-0;
      @apply w-full;
      background: linear-gradient(50deg, #000 40%, rgba(0, 0, 0, 0) 58%);
      @apply rounded-2xl;
    }
  }
  &__content-wrapper {
    @apply absolute;
    @apply bottom-10;
    @apply w-full;
    @apply px-6;
    @screen sm {
      @apply top-16;
      @apply bottom-0;
      @apply left-12;
      @apply w-1/2;
      @apply px-0;
    }
    @screen l {
      @apply top-[59px];
    }
  }

  &__title-text {
    @apply text-white;
    @include truncate(2);
    @include font-heading('mobile', 'h3');
    @screen sm {
      @include font-heading('mobile', 'h2');
    }
    @screen md {
      @include font-heading('desktop', 'h2');
    }
  }

  &__excerpt {
    @apply text-gray-300;
    @apply overflow-auto;
    @include custom-scroll;
    @include font-text('mobile', 'large');
    @apply mt-4;
    @apply h-[85px];
    @screen sm {
      @include font-text('desktop', 'large');
      @apply w-[347px];
      @apply mt-6;
    }
  }

  &__button-wrapper {
    @apply flex;
    @apply self-center;
    @apply mx-auto;
    @apply text-black;
    @apply mt-2;
    @screen sm {
      @apply w-[264px];
      @apply ml-0;
      @apply mt-4;
    }
  }
  &__button {
    @apply bg-white;
    @apply text-black;
    @apply min-h-0;
    @apply py-[11px];
    @apply px-[28px];
    @include font-button('desktop');
  }
}
