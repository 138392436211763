@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/thumbnail.module.scss' as *;

.drive-guide-article-posts {
  @apply w-full-screen;
  @apply bg-gray-200;
  @apply flex;
  @apply flex-col-reverse;
  @apply px-6;
  @apply relative;
  left: calc(-50vw + 50%);
  @screen sm {
    @apply flex-row-reverse;
  }

  @screen lg {
    @apply justify-center;
    &__page-takeover {
      @apply left-0;
      @apply w-full;
    }
  }
  @screen xl {
    @apply relative;
    @apply px-0;
  }

  &__wrapper {
    @apply flex;
    @apply flex-col-reverse;

    @screen sm {
      @apply flex-row-reverse;
    }

    @screen lg {
      @apply justify-center;
    }
    @screen xl {
      max-width: 1216px;
    }
  }

  &__main-section {
    @apply bg-gray-200;
    @apply flex;
    @apply flex-col-reverse;
    @apply pt-6;
    @apply pb-6;
    @apply items-end;

    @screen sm {
      @apply flex-col;
      width: 40%;
      @apply p-0;
      @apply mt-[40px];
    }
    @screen xl {
      width: 32.5%;
    }

    [class^='articleAnnexCard_drive-annex-cards__wrapper'] {
      @apply w-full;
      @screen sm {
        width: auto;
      }
    }

    [class^='articleAnnexCard_drive-annex-cards__container'] {
      @apply mb-4;
      height: 102px;
      @screen sm {
        height: 74px;
      }
      @screen xl {
        @apply h-28;
      }
    }

    [class^='articleAnnexCard_drive-annex-cards__content'] {
      @apply bg-white;
      @apply border-0;
      @apply pt-[9px];
      @screen xl {
        @apply pr-[11px];
      }

      [class^='articleAnnexCard_drive-annex-cards__heading'] {
        @apply max-h-10;
        @screen xl {
          @apply max-h-full;
        }
      }

      [class^='subtexts_drive-annex-card__subtext-date'] {
        @include font-subtext('desktop', 'small');
      }
      [class^='drive-icon drive-icon-CommentIcon'] {
        height: 10px;
        width: 10px;
        @screen xl {
          @apply h-4;
          @apply w-4;
        }
      }
      [class^='comments-count_drive-article-card__comment__count'] {
        @include font-subtext('desktop', 'small');
      }
    }

    [class^='thumbnail_drive-annex-card__link'] {
      width: auto;
    }

    &__articles-link {
      @apply text-blue-primary;
      @apply mt-12;
      @include font-subtext('desktop', 'medium');
      @screen sm {
        @apply h-14;
        @apply mt-0;
        @apply mb-6;
      }
    }

    &__articles-slider {
      max-height: 500px;
      @screen sm {
        max-height: 400px;
      }
      @screen xl {
        max-height: 550px;
      }
      @apply w-full;
      :global(.slick-arrow)::before {
        border-color: black;
      }
      :global(.slick-arrow.slick-prev) {
        &:global(.slick-disabled)::before {
          @apply border-gray-300;
        }
        max-height: 0;
      }
      :global(.slick-arrow.slick-next) {
        max-height: 0;
        &:global(.slick-disabled)::before {
          @apply border-red-300;
        }
      }

      &-gradient {
        @apply relative;
        &:before {
          @apply content-[''];
          @apply absolute;
          @apply w-full;
          @apply bottom-[8px];
          @apply h-3.5;
          @apply left-0;
          z-index: 1;
          background: #efeeee;
          background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #efeeee 100%);
          background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #efeeee 100%);
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #efeeee 100%);
        }
        :global(.slick-slide):last-child {
          [class^='articleAnnexCard_drive-annex-cards__container'] {
            @apply mb-0;
          }
        }

        :global(.slick-slider.vertical.slick-vertical.slick-initialized) {
          @apply static;
        }
      }
    }
  }

  &__header {
    @apply pt-8;
    @apply pb-6;
    @apply px-[27px];
    @apply text-center;
    @include font-heading('mobile', 'h3');
    @screen sm {
      @apply mt-0;
      @apply text-left;
      @apply p-0;
      @apply mb-8;
      @apply h-14;
    }
    @screen xl {
      @include font-heading('desktop', 'h3');
    }
  }

  &__sub-section {
    @apply pb-8;
    @screen sm {
      margin-top: 40px;
      @apply pr-6;
      @apply pb-14;
      width: 60%;
    }

    @screen xl {
      width: 68.5%;
      @apply mr-[119px];
      max-width: 600px;
      @apply pr-0;
    }
  }
}
