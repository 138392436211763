@mixin customUnderline() {
  @apply relative;
  &:after {
    content: '';
    @apply h-[2px];
    @apply w-full;
    @apply absolute;
    @apply left-0;
    @apply bottom-[-3px];
    @apply bg-black;
    animation: 0.1s fadeIn forwards ease-in;
    @content;
  }
}
