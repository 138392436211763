@use '/styles/mixin/scroll.module.scss' as *;

.drive-sub-navigation__more-dropdown {
  @apply border-l-2;
  @apply border-white;
  @apply border-solid;
  @apply h-7;

  @apply w-100px;
  @screen md {
    @apply w-[175px];
    @apply max-w-[175px];
  }
  & > * {
    @apply select-none;
  }
  &__button {
    @apply relative;
    @apply cursor-pointer;
    @apply flex;
    @apply items-center;
    @apply justify-between;
    @apply w-full;
    @apply h-full;
    @apply pl-4;
    @screen md {
      @apply pl-[21px];
      @apply pr-3;
    }
  }
  &__chevron {
    @apply ml-1;
    @apply w-[17px];
    @apply h-[10px];
    &--up {
      @apply scale-y-[-1];
    }
    &--nudge {
      @keyframes nudge {
        from {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-4px);
        }
        to {
          transform: translateY(0);
        }
      }
      animation: nudge 0.5s ease-in-out;
    }
  }

  // apply gray border only if the dropwdown is open and NOT sticky
  ul[class*='more-dropdown_drive-sub-navigation__more-dropdown__item-list--open']:not([class*='drive-sub-navigation--sticky']
      *) {
    @apply shadow-md;
    @apply border-solid;
    @apply border-2;
    @apply border-gray-300 #{!important};
  }

  &__item-list {
    @apply z-[21]; //avoid dropdown being covered by ratings component
    @apply absolute;
    @apply flex;
    @apply flex-col;
    @apply mt-3;
    @screen lmd {
      @apply mt-4;
    }
    @apply pb-3;
    //consider heights of sticky ad, navbar, subnav,
    //gam_textstickymobile, and iphone floating address bar.
    max-height: calc(100vh - 220px);
    @apply overflow-x-hidden;
    @apply overflow-y-scroll;
    transition: opacity 200ms linear;
    @include custom-scroll;
    &::-webkit-scrollbar-thumb {
      @apply bg-gray-500;
    }
    @apply right-0;
    @apply invisible;
    @apply opacity-0;
    @apply w-[198px];
    @apply -mr-5;

    @screen md {
      @apply mr-0;
      @apply w-[175px];
    }
    @apply bg-black;

    &--open {
      @apply visible;
      @apply opacity-100;
    }
    & > [class^='sub-navigation_drive-sub-navigation__menu-item'] {
      @apply flex-wrap;
      @apply whitespace-normal;
      @apply p-3;
      @apply h-auto;
      width: fit-content;
      &:after {
        width: calc(100% - 2rem);
        @apply left-4;
        @apply mb-1;
      }
    }
  }
}
