.d-nused-badge {
  /**
   * These very hard-coded values were generated by Figma.
   * @see https://www.figma.com/design/Nnv9j54JeWnlBxX89ZNLDg/DISPATCH---Marketplace?node-id=7900-12067&t=9TT8WNjE6WpW4j0u-4
   */
  background: linear-gradient(87.93deg, #0a256e 22.7%, #2960c5 202.04%);
  @apply py-[6px];
  @apply pl-[7px];
  @apply pr-1.5;
  @apply rounded-[4px];
  @apply w-[73px];
  @apply h-6;
}
