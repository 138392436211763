@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/variables.scss' as *;

// before adding any overflow-hidden within the component,
// please ensure more dropdown is not affected
.drive-sub-navigation {
  &-spacer {
    @apply flex;
    @apply h-[52px];
    @apply w-full;
    @apply relative; //avoid being covered by other stacks at same level when PTO
    @screen lmd {
      @apply h-16;
    }
  }

  @apply w-full;
  @apply bg-black;
  @apply h-[52px];
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @screen lmd {
    @apply h-16;
  }
  &--sticky {
    @apply fixed;
    @apply top-0;
    @apply left-0;
    @apply flex;
    @apply justify-center;
    z-index: $z-index-header;
    @apply top-20;
    @screen md {
      @apply top-0;
    }
  }
  &--page-take-over {
    @apply max-w-[1280px] #{!important}; // avoid styles from usesubnav
  }
  &--under-sticky-header {
    @apply left-1/2;
    @apply -translate-x-1/2;
    @apply pl-0;
    @media (min-width: 1280px) {
      @apply top-20 #{!important}; // avoid styles from usesubnav
    }
  }

  &__container {
    @apply flex-1;
    @apply max-w-[1024px];
    @screen xl {
      @apply max-w-1240;
    }
    @apply flex;
    @apply overflow-x-hidden; //required for first load to check if more menu is needed
    @apply h-full;
    @apply items-center;
    @apply justify-start;
    &::-webkit-scrollbar {
      @apply hidden;
    }
    &--has-more {
      @apply overflow-x-visible; //allow menu dropdown to be visible.
    }
  }

  &__menu-item {
    @apply flex;
    @apply flex-nowrap;
    @apply cursor-pointer;
    @apply items-center;
    @apply h-8;
    @apply whitespace-nowrap;
    &:not(:last-child) {
      @apply shrink-0;
      @apply mr-5;
      @screen md {
        @apply mr-[2.625rem];
      }
    }

    @mixin underline {
      @apply relative;
      &:after {
        content: '';
        @apply h-[0.125rem]; //2px
        width: calc(100% - 1rem);
        @apply left-2;
        @apply absolute;
        @apply bottom-0;
        @apply bg-white;
        animation: 0.1s fadeIn forwards ease-in;
      }
    }

    &--custom-highlight,
    &--current-in-view {
      @include underline;
    }
    &:not(&--dropdown-wrapper):hover {
      //only for non-touch screen
      @media (hover: hover) {
        @include underline;
      }
    }
    &--dropdown-wrapper {
      @apply cursor-default;
      @apply flex-grow;
      @apply justify-end;
      @apply h-7;
      @apply flex;
      @apply items-center;
      @apply relative;
    }

    &__link,
    &--dropdown-wrapper {
      @apply text-white;
      @include font-subtext('mobile', 'large');
      @screen lmd {
        @include font-subtext('desktop', 'large');
      }
      &:hover {
        @apply text-white;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
